import { Form, Input, message } from "antd";

import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import Footer from "../../../components/Footer";

import CustomerSurvey from "./../../../assets/customer-survey.svg";
import { useForm } from "antd/es/form/Form";

import { useNavigate } from "react-router-dom";
import {
  performGetUserProfile,
  performLogin,
} from "../../../redux/auth/authActions";
import { useAppDispatch } from "../../../redux/hooks";
import { getCookie } from "../../../utils/helper";
import {
  GlobalStyle,
  PurplePrimaryButton,
} from "../../../shared/Global.styled";

const Login = () => {
  const [form] = useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { redirectedFrom } = useParams<{ redirectedFrom?: string }>();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);

      const loginResponse = await dispatch(performLogin(values));

      if (loginResponse.payload?.status == false) {
        let errorMsg = "";
        if (loginResponse.payload.error === "INACTIVE_USER") {
          errorMsg =
            "You cannot log in because your account is inactive. Please contact the SurveyStream team.";
        } else if (loginResponse.payload.error === "UNAUTHORIZED") {
          errorMsg =
            "Login failed, kindly check your credentials and try again.";
        } else {
          errorMsg = "Something went wrong!";
        }
        messageApi.open({
          type: "error",
          content: errorMsg,
        });
        return false;
      }

      await dispatch(performGetUserProfile());

      navigate("/surveys");
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Login failed, kindly check your credentials and try again",
      });
    } finally {
      setLoading(false);
    }
  };

  //TODO: Finish redirected from and to feature
  // useEffect(() => {
  //   if (redirectedFrom) {
  //     messageApi.open({
  //       type: "info",
  //       content:
  //         "Because you are not logged in, you have been redirected to the login page.",
  //     });
  //   }
  // }, [redirectedFrom]);

  useEffect(() => {
    const rememberToken = getCookie("remember_token");
    if (rememberToken !== "") {
      navigate("/surveys");
    }
  }, []);
  return (
    <>
      <GlobalStyle />

      <div className="mb-[80px]">
        <div className="mt-[44px]">
          <p className="font-normal font-medium text-[30px] leading-[38px] text-gray-9 m-0 text-center">
            Login to SurveyStream
          </p>
        </div>
        <div className="mt-[72px] mb-[10px] flex">
          <div className="ml-[54px]">
            <img className="w-[739px] h-[361px]" src={CustomerSurvey} />
          </div>
          <div
            className="ml-[111px] w-[400px] h-[300px] px-[24px] py-[22px] bg-gray-2 
            border-solid border border-[#F0F0F0] rounded-lg shadow-[0px_4px_4px_rgba(0,0,0,0.08)]"
          >
            {contextHolder}

            <Form
              name="loginForm"
              layout="vertical"
              autoComplete="off"
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                id="loginForm_email"
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter email" },
                  { type: "email", message: "Please enter vaild email" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                id="loginForm_password"
                label="Password"
                name="password"
                rules={[{ required: true, message: "Please enter password" }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => (
                  <PurplePrimaryButton
                    id="loginForm_submit"
                    type="primary"
                    htmlType="submit"
                    block
                    className="w-full h-[40px]"
                    loading={loading}
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Login
                  </PurplePrimaryButton>
                )}
              </Form.Item>
            </Form>
            <Link
              to="/reset-password"
              className="font-inter font-normal font-medium text-xs leading-[20px] text-gray-9 float-right no-underline"
            >
              Forgot password?
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;

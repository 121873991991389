export const surveyConfigs: Record<string, any> = {
  "Basic information": {
    status: "Not Started",
  },
  "Module selection": {
    status: "Not Started",
  },
  "Survey information": [
    {
      name: "SurveyCTO information",
      status: "Not Started",
    },
    { name: "User and role management", status: "Not Started" },
    {
      name: "Survey locations",
      status: "Not Started",
    },
    {
      name: "Enumerators",
      status: "Not Started",
    },
    {
      name: "Targets",
      status: "Not Started",
    },
    {
      name: "Target status mapping",
      status: "Not Started",
    },
    {
      name: "Mapping",
      status: "Not Started",
    },
  ],
  "Module configuration": [],
};

import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #ffffff;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

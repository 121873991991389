import styled from "styled-components";

export const RowEditingModalContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  background-color: #fff;
  top: 0;
  right: 0;
  min-width: 500px;
  padding-top: 20px;
  padding-left: 60px;
  padding-right: 30px;
`;

export const RowEditingModalHeading = styled.p`
    color: #262626,
    font-family: "Lato", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
`;

export const OptionText = styled.span`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #434343;
`;

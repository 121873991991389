import styled from "styled-components";

export const LoaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoaderText = styled.div`
  margin-top: 10px;
  color: #10239e;
  text-align: center;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
`;
